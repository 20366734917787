'use strict';

document.addEventListener('DOMContentLoaded', function () {
    setTimeout(function () {
        loadNoAnchorRecommendations();
    }, 1000);
});

/**
 * Validates and Return the cquotient namespace provided by the commerce cloud platform
 */
function getEinsteinUtils() {
    var einsteinUtils = window.CQuotient;
    if (einsteinUtils && (typeof einsteinUtils.getCQUserId === 'function') && (typeof einsteinUtils.getCQCookieId === 'function')) {
        return einsteinUtils;
    }
    return null;
}

/**
 * Rerieves data attributes from parent element and converts to gretel compatible recommenders array
 * @param {jQuery} $parentElement parent element where recommendations will show.
 * @returns {Array} - containing an anchor object
 */
function createCategoryAnchor($parentElement) {
    return [{ id: $parentElement.data('categoryId') }];
}

/**
 * Gets all placeholder elements, which hold einstein recommendations queries the details from the
 * einstein engine and feeds them back to the dom element
 */
function loadNoAnchorRecommendations() {
    var einsteinUtils = getEinsteinUtils();
    if (einsteinUtils) {
        var recommendationTiles = Array.from(document.querySelectorAll('.einstein-noanchor-recommendations'));
        recommendationTiles.forEach(parentElement => {
            var $parentElement = $(parentElement);
            if ($parentElement.hasClass('einsteinCarouselCategory')) {
                return processRecommendationsTile(parentElement, einsteinUtils, createCategoryAnchor($parentElement));
            }

            return processRecommendationsTile(parentElement, einsteinUtils)
        });
    }
}

/**
 * Processes a recommendation tile, with an already initialized category specific anchors array
 */
function processRecommendationsTile(parentElement, einsteinUtils, anchorsArray) {
    var einsteinParameters = parentElement.dataset;
    var recommender = einsteinParameters.recommender;

    var params = {
        userId: einsteinUtils.getCQUserId(),
        cookieId: einsteinUtils.getCQCookieId(),
        ccver: '1.01'
    };

    if (anchorsArray) {
        params.anchors = anchorsArray;
    }

    function recommendationsReceived(einsteinResponse) {
        fillEinsteinDomElement(einsteinResponse, parentElement);
    }

    if (einsteinUtils.getRecs) {
        einsteinUtils.getRecs(einsteinUtils.clientId, recommender, params, recommendationsReceived);
    } else {
        einsteinUtils.widgets = einsteinUtils.widgets || [];
        einsteinUtils.widgets.push({
            recommenderName: einsteinParameters.recommender,
            parameters: params,
            callback: recommendationsReceived
        });
    }
}

/**
 * Renders the einstein response into a given dom element
 */
function fillEinsteinDomElement(einsteinResponse, parentElement) {
    var recommendedProducts = einsteinResponse[parentElement.dataset.recommender].recs;
    if (recommendedProducts && recommendedProducts.length > 0) {
        var template = parentElement.dataset.template;
        var components = [];
        components = recommendedProducts.map(recommendedProduct => {
            var tiledefinition = {};
            tiledefinition.template = template;
            tiledefinition.model = {
                type: 'product',
                id: recommendedProduct.id
            };
            return tiledefinition;
        });
        var url = new URL(parentElement.dataset.productLoadUrl);
        $.ajax({
            url: url.href,
            type: 'get',
            dataType: 'html',
            data: {
                components: JSON.stringify(components),
                limit: parentElement.dataset.limit
            },
            success: function (data) {
                if (data) {
                    parentElement.innerHTML = data;
                    $('body').trigger('einstein:loaded', {
                        parent: parentElement
                    });
                    var recommendationsLoaded = new Event('recommendation.slot.loaded');
                    window.dispatchEvent(recommendationsLoaded);
                } else {
                    // If there are no product tiles to show, hide the recommendation container
                    $('.recommendations').addClass('hidden');
                }
            },
            error: function (e) {
                // In case of error remove the container and its title.
                var container = document.getElementById(parentElement.dataset.containerId);
                container.remove();
            }
        });
    } else {
        $('.recommendations').addClass('hidden');
    }
}
